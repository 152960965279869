import React, { useState, useEffect } from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import Close from "../../components/close"

const HeaderNavigation = ({ links, onToggle }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setTimeout(() => setActive(true), 100)
  }, [setActive])

  const onClose = () => {
    setActive(false)
    onToggle(false, "Navigation")
  }

  var navClass = classNames({
    nav: true,
    header__nav: true,
    active,
  })

  return (
    <nav className={navClass}>
      {links.map(link => (
        <Link
          className="nav__link"
          to={link.href}
          activeClassName="active"
          key={link.label}
        >
          {link.label}
        </Link>
      ))}
      <Close onClick={onClose}></Close>
    </nav>
  )
}

export default HeaderNavigation