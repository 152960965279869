import React from "react"
import { Link } from "gatsby"

const Logo = ({ siteName }) => {
  return (
    <Link to="/" className="logo">
      <span className="logo__icon">
        <svg
          version="1.1"
          className="icon"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 300 345.8"
        >
          <title>HomeWork-cool.RU</title>
          <g id="sh">
            <polygon
              className="st0"
              points="245,128 300,96.2 300,86.2 245,118"
            />
            <polygon
              className="st0"
              points="245,148 300,116.2 300,106.2 245,138"
            />
            <polygon
              className="st0"
              points="245,173 300,141.2 300,126.2 245,158"
            />
            <polygon className="st0" points="55,158 0,126.2 0,141.2 55,173" />
            <polygon
              className="st0"
              points="300,259.2 300,151.2 245,183 245,227.4 150,282.3 55,227.4 55,183 0,151.2 0,259.2 150,345.8"
            />
            <polygon className="st0" points="55,138 0,106.2 0,116.2 55,148" />
            <polygon className="st0" points="55,118 0,86.2 0,96.2 55,128" />
          </g>
          <path
            id="sp"
            className="st0"
            d="M225,43.3L150,0L75,43.3l75,215.9L225,43.3z M122.5,43.6c0-15.2,12.3-27.5,27.5-27.5 s27.5,12.3,27.5,27.5S165.2,71.1,150,71.1S122.5,58.8,122.5,43.6z M150,93l35-26.7l-35,105.9L115,66.3L150,93z"
          />
        </svg>
      </span>
      {siteName && <span className="logo__name">{siteName}</span>}
    </Link>
  )
}

export default Logo
