import React from "react"
import { Link } from "gatsby"
import Logo from "../components/logo"

const Footer = ({ navigation }) => {
  const year = new Date().getFullYear()
  const siteName = "HomeWork-cool.RU"
  return (
    <footer className="footer" itemScope itemType="https://schema.org/WPFooter">
      <div className="footer__top">
        <Logo />
        <nav
          className="nav footer__nav"
          itemScope
          itemType="https://schema.org/SiteNavigationElement"
        >
          {navigation.slice(1).map(item => (
            <Link className="nav__link" to={item.href} key={item.label}>
              {item.label}
            </Link>
          ))}
        </nav>
      </div>
      <div className="footer__bottom">
        <div className="copyright">
          &copy;
          <span itemProp="copyrightYear">{year}</span>
          {`, `}
          <span itemProp="name">{siteName}</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer