import React from "react"
import "./styles.sass"

const Burger = () => {
  return (
    <span className="burger" aria-label="Открыть меню">
      <span></span>
    </span>
  )
}

export default Burger