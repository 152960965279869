import React from "react";
import styled from "styled-components";

const Close = ({ onClick }) => {
  return (
    <CloseButton
      aria-label="Закрыть"
      onClick={() => onClick(false)}
    ></CloseButton>
  );
};

export default Close

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  height: 70px;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  --icon-size: 24px;

  &:before,
  &:after {
    position: absolute;
    top: calc(50% - var(--icon-size) / 2);
    left: calc(50% - var(--icon-size) / 2);
    content: " ";
    height: var(--icon-size);
    width: 2px;
    background-color: var(--close-color, var(--color-on-surface));
    transition: all 0.3s ease;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;
