import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Breadcrumb = ({ list }) => {
  const BASE_URL = process.env.WEB_BASE_URL || "http://localhost:8080"

  const isLast = (idx) => (list.length === idx + 1)

  const listItemsJSX =
    Array.isArray(list) &&
    list.map(({ to, name }, idx) => {
      const renderLink = isLast(idx) ? <span itemProp="item" className="--disabled"><span itemProp="name">{name}</span></span> : <Link
          to={to}
          itemProp="item"
          itemScope
          itemType="https://schema.org/WebPage"
          itemID={`${BASE_URL}${to}`}
        >
          <span itemProp="name">{name}</span>
        </Link>
      return (
      <BreadcrumbListItem
        itemprop="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
        key={to}
      >
        {renderLink}
        <meta itemProp="position" content={idx + 1} />
      </BreadcrumbListItem>
    )})

  return (
    <BreadcrumbList itemScope itemType="https://schema.org/BreadcrumbList">
      {listItemsJSX}
    </BreadcrumbList>
  )
}

const BreadcrumbList = styled.ol`
  height: 26px;
  overflow: hidden;
`

const BreadcrumbListItem = styled.li`
  display: inline-block;
  margin-right: 0.5rem;
  &:not(:first-child):before {
    content: "/";
    opacity: 0.2;
    margin-right: 0.5rem;
  }
  a {
    color: currentColor;
    transition: opacity 350ms ease-in-out;
    &:not(:hover) {
      opacity: 0.5;
    }
  }

  .--disabled {
    opacity: 0.3
  }
`

export default Breadcrumb
