import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"

import Logo from "../logo"
import Navigation from "../header-navigation"
import Menu from "../header-menu"
import { CurrentUserContext } from "../../context/currentUser"
import useLocalStorage from "../../hooks/useLocalStorage"

const Header = ({ menu, navigation }) => {
  const [tree, setTree] = useState([])
  const [darkTheme, setDarkTheme] = useLocalStorage("darkTheme", false)
  
  const [{ isLoggedIn }] = useContext(CurrentUserContext)

  useEffect(() => {
    darkTheme
      ? document.body.classList.add("dark-theme")
      : document.body.classList.remove("dark-theme")
  }, [darkTheme])

  const onToggle = (status, name) => {
    const idx = tree.findIndex(item => item === name)

    if (status === false && idx !== -1) {
      const newTree = [...tree.slice(0, idx), ...tree.slice(idx + 1)]
      setTimeout(() => setTree(newTree), 300)
    } else {
      const newTree = [...tree, name]
      setTree(newTree)
    }
  }

  const actions = {
    onAuth: () => {
      navigate(isLoggedIn ? "/dashboard" : "/dashboard/login")
    },
    onSearch: () => {
      console.log("onSearch")
    },
    onForm: () => {
      console.log("onForm")
    },
    onDarkTheme: () => {
      setDarkTheme(!darkTheme)
    },
  }

  return (
    <header className="header" itemScope itemType="https://schema.org/WPHeader">
      <Logo siteName="HomeWork Cool" />
      <Menu menu={menu} onAction={key => actions[key]()} onToggle={onToggle} />

      <div className="header__container">
        {tree.map(name => {
          switch (name) {
            case "Navigation":
              return (
                <Navigation links={navigation} onToggle={onToggle} key={name} />
              )
            default:
              return null
          }
        })}
      </div>
    </header>
  )
}

export default Header
