import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Icon from "../icon"
import BreadcrumbList from "../breadcrumb"

const PageHero = ({ breadcrumbs, backLink, children }) => {

  const backLinkJSX = backLink ? (
    <Link to={backLink} title="Назад">
      <Icon name="arrow-left" />
    </Link>
  ) : null

  return (
    <Hero>
      <HeroNav>
        <BreadcrumbList list={breadcrumbs} />
      </HeroNav>

      <HeroBack>{backLinkJSX}</HeroBack>

      {children}
    </Hero>
  )
}

export default PageHero

const Hero = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 30vh;
  padding-top: 75px;
  background: rgba(var(--hero-color), 0.15);
  color: var(--color-on-surface);
  h1 {
    color: var(--color-on-hero);
  }
`

const HeroNav = styled.div`
  width: 100%;
  padding: 0.5rem var(--offset-one-side) 0.5rem
    calc(100% / var(--columns) * var(--hero-nav-shift) + var(--offset));
`

const HeroBack = styled.div`
  --icon-size: var(--hero-back-icon-size);
  display: flex;
  justify-content: flex-end;
  width: calc(100% / var(--columns) * var(--hero-back-size));
  padding-left: var(--offset-one-side);
  padding-right: var(--offset-one-side);
  padding-top: var(--hero-back-pt);
  &:not(:hover) {
    --icon-color: var(--color-on-surface);
    opacity: 0.5;
  }
`

export const HeroContent = styled.div`
  width: calc(100% / var(--columns) * var(--hero-content-size));
  min-height: 25vh;
  padding-left: var(--offset-one-side);
  padding-right: var(--offset-one-side);
  padding-bottom: 10vh;
`
