import React from "react"
import Icon from "../../components/icon"
import Burger from "../../components/burger"

const HeaderMenu = ({ menu, onAction, onToggle }) => {
  return (
    <div className="menu menu--gorizontal">
      {menu.map(item => (
        <button
          className={item.className}
          title={item.title}
          key={item.icon}
          onClick={() => onAction(item.action)}
        >
          <Icon name={item.icon} />
        </button>
      ))}
      <button
        className="menu__item"
        onClick={() => onToggle(true, "Navigation")}
      >
        <Burger />
      </button>
    </div>
  )
}

export default HeaderMenu